// src/services/doctorService.ts

import { AxiosResponse } from 'axios';
import axiosInstance from '../axios';
import { API_ENDPOINTS } from '../endpoints';

export const doctorService = {
  getVisitsByPatient: (patientId: number): Promise<AxiosResponse<any>> => {
    return axiosInstance.get(API_ENDPOINTS.DOCTOR.GET_VISITS_BY_PATIENT, {
      params: { patientId },
    });
  },

  getVisitsByDoctor: (docId: number): Promise<AxiosResponse<any>> => {
    return axiosInstance.get(API_ENDPOINTS.DOCTOR.GET_VISITS_BY_DOCTOR, {
      params: { docId },
    });
  },

  getVisitsByClinic: (clinicId: number): Promise<AxiosResponse<any>> => {
    return axiosInstance.get(API_ENDPOINTS.DOCTOR.GET_VISITS_BY_CLINIC, {
      params: { clinicId },
    });
  },

  getMyConsultations: (docId: number): Promise<AxiosResponse<any>> => {
    return axiosInstance.get(API_ENDPOINTS.DOCTOR.GET_MY_CONSULTATIONS(docId));
  },
};
