import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store';
import {
  registerPatient,
  selectPatientLoading,
  selectPatientError,
  selectRegistrationSuccess,
  resetRegistrationStatus
} from '../../store/slices/patientSlice';
import { UPatientInfo } from '../../api/types';
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  Grid,
  Alert,
  CircularProgress,
  MenuItem,
  Container
} from '@mui/material';

const initialFormData: UPatientInfo = {
  clinicId: 0,
  title: '',
  gender: '',
  phone: '',
  email: '',
  dateOfBirth: '',
  address: '',
  emergencyContactName: '',
  emergencyPhone: '',
  specialization: '',
  docId: 0,
  vitals: '',
  symptoms: '',
  fname: '',
  lname: '',
  firstName: '',
  lastName: '',
  medicalHistory: '',
  insuranceNumber: null,
  occupation: null,
  bloodType: null,
  allergies: null,
  chronicConditions: null,
  currentMedications: null
};

const PatientRegistration: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const loading = useSelector(selectPatientLoading);
  const error = useSelector(selectPatientError);
  const registrationSuccess = useSelector(selectRegistrationSuccess);

  const [formData, setFormData] = useState<UPatientInfo>(initialFormData);
  const [formError, setFormError] = useState<string | null>(null);

  useEffect(() => {
    // Reset form when registration is successful
    if (registrationSuccess) {
      setFormData(initialFormData);
    }
    // Cleanup when component unmounts
    return () => {
      dispatch(resetRegistrationStatus());
    };
  }, [dispatch, registrationSuccess]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value,
      ...(name === 'firstName' ? { fname: value } : {}),
    }));
    setFormError(null);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!formData.firstName) {
      setFormError('First name is required');
      return;
    }
    if (!formData.gender) {
      setFormError('Gender is required');
      return;
    }
    if (!formData.phone && !formData.email) {
      setFormError('Either phone or email is required');
      return;
    }

    await dispatch(registerPatient(formData));
  };

  const resetForm = () => {
    setFormData(initialFormData);
    setFormError(null);
  };

  return (
    <Box sx={{ 
      width: '100%', 
      maxWidth: '900px', 
      mx: 'auto', 
      px: { xs: 2, sm: 3 }
    }}>
      <Paper sx={{ p: { xs: 2, sm: 3 }, mb: 3 }}>
        <Typography 
          variant="h6" 
          gutterBottom
          sx={{ mb: 3 }}
        >
          Patient Registration
        </Typography>

        {(error || formError) && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {formError || error}
          </Alert>
        )}

        {registrationSuccess && (
          <Alert severity="success" sx={{ mb: 2 }}>
            Patient registered successfully!
          </Alert>
        )}

        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {/* Required Fields */}
            <Grid item xs={12} md={4}>
              <TextField
                required
                fullWidth
                label="First Name"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                error={formError?.includes('First name')}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Last Name"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                select
                required
                fullWidth
                label="Gender"
                name="gender"
                value={formData.gender}
                onChange={handleInputChange}
                error={formError?.includes('Gender')}
              >
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </TextField>
            </Grid>
            
            {/* Contact Information - At least one required */}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Phone Number"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                error={!formData.email && !formData.phone && formError?.includes('phone or email')}
                helperText={!formData.email && !formData.phone ? "Either phone or email is required" : ""}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                type="email"
                label="Email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                error={!formData.email && !formData.phone && formError?.includes('phone or email')}
                helperText={!formData.email && !formData.phone ? "Either phone or email is required" : ""}
              />
            </Grid>

            {/* Optional Fields */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Address"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Medical History"
                name="medicalHistory"
                value={formData.medicalHistory}
                onChange={handleInputChange}
                multiline
                rows={3}
              />
            </Grid>
          </Grid>

          <Box sx={{ 
            mt: 3, 
            display: 'flex', 
            gap: 2,
            flexDirection: { xs: 'column', sm: 'row' },
            '& .MuiButton-root': { minWidth: '120px' }
          }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
              sx={{ flex: { sm: '0 0 auto' } }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Register Patient'
              )}
            </Button>
            <Button
              type="button"
              variant="outlined"
              onClick={resetForm}
              disabled={loading}
              sx={{ flex: { sm: '0 0 auto' } }}
            >
              Reset Form
            </Button>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

export default PatientRegistration;