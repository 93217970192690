import React, { useState, useEffect } from "react";
import firebase from "../../firebase";
import {
  Auth,
  UserCredential,
  AuthError,
  signInWithEmailAndPassword,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink
} from "firebase/auth";
import {
  auth,
  signInWithGooglePopup,
  signInWithApplePopup,
} from "../../firebase";
import {
  set,
  ref,
  getDatabase,
  onValue,
  remove,
  update,
  Database,
  DatabaseReference
} from "firebase/database";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { loginSuccess } from '../../store/slices/authSlice'; // Import the specific action
import googleIcon from "../../assets/google.png";
import microsoftIcon from "../../assets/microsoft.png";

interface CopyrightProps {
  sx?: React.CSSProperties;
}

interface RootState {
  auth?: {
    GetStarted?: boolean;
  };
}

function Copyright(props: CopyrightProps): JSX.Element {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function SigninConfirm(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [notice, setNotice] = useState<string>("");
  const [loggedinSuccess, setLoggedinSuccess] = useState<boolean>(false);
  const [loggedinMessage, setLoggedinMessage] = useState<string>("Checking ...");
  const [subloggedinMessage, setSubLoggedinMessage] = useState<string>("");
  
  const getstarted = useSelector((state: RootState) => state?.auth?.GetStarted);

  useEffect(() => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      let email = window.localStorage.getItem('emailForSignIn');
      let redirectPath = window.localStorage.getItem('redirect');
      
      signInWithEmailLink(auth, email ?? '', window.location.href)
        .then(async(response: UserCredential) => {
          setLoggedinMessage('Logging in with your mail');
          setSubLoggedinMessage('Email Has been verified Successfully.');
          console.log('signInWithEmailLink', response);
 
          await dispatch(loginSuccess(response));
          await window.localStorage?.setItem('authUser', JSON.stringify(response));
          
          if (redirectPath) {
            const decodedPath = decodeURIComponent(redirectPath);
            navigate(redirectPath);
          } else {
            navigate('/');
          }
        })
        .catch((error: AuthError) => {
          console.log('error', error);
          setLoggedinMessage('Logging in with your mail failed');
          setSubLoggedinMessage('The email provided does not match the sign-in email address.');
        });
    }
  }, [dispatch, navigate]);

  const loginWithUsernameAndPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("./");
    } catch (error) {
      setNotice("You entered a wrong username or password.");
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const actionCodeSettings = {
      url: 'http://localhost:3000/signin-confirm',
      handleCodeInApp: true,
    };

    const emailValue = data.get("email");
    const passwordValue = data.get("password");

    if (typeof emailValue === "string") {
      try {
        await sendSignInLinkToEmail(auth, emailValue, actionCodeSettings)
          .then((response) => {
            console.log("response", response);
            window.localStorage.setItem("emailForSignIn", emailValue);
          })
          .catch((error: AuthError) => {
            const errorCode = error.code;
            const errorMessage = error.message;
          });

        window.localStorage.setItem("emailForSignIn", emailValue);
      } catch (error) {
        setNotice("You entered a wrong username or password.");
      }
    }
  };

  const logGoogleUser = async (): Promise<void> => {
    try {
      const response = await signInWithGooglePopup();
      console.log(response);
      
      const db: Database = getDatabase();
      const regex = /[.#$\[\]@]/g;
      const path = response?.user?.email?.replace(regex, "-") ?? "";
      const userRef: DatabaseReference = ref(db, path);
      
      onValue(userRef, (snapshot) => {
        const data = snapshot.val();
        if (!!data && data?.onboarding === true) {
          navigate("/home");
        } else {
          console.log("Data not found");
          if (!getstarted) {
            navigate("/getstarted");
          } else {
            navigate("/onboarding/termnconditions");
          }
        }
      });
      
      await dispatch(loginSuccess(response));
    } catch (error) {
      setNotice("You entered a wrong username or password.");
    }
  };

  const logAppleUser = async (): Promise<void> => {
    try {
      const response = await signInWithApplePopup();
      console.log(response);
      navigate("/");
    } catch (error) {
      setNotice("You entered a wrong username or password.");
    }
  };

  return (
    <>
      <Grid container sx={{ height: "calc(100vh - 120px)" }}>
        <CssBaseline />
        <Grid item xs={12} sm={12} md={12}>
          <Paper 
            elevation={6} 
            square 
            sx={{ 
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                maxWidth: "50%",
                gap: "16px"
              }}
            >
              <Typography
                variant="h4"
                sx={{ color: "#12190F", fontSize: "32px", fontWeight: "700" }}
              >
                {loggedinMessage}
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#ABABAB", fontSize: "18px", fontWeight: "500" }}
              >
                {subloggedinMessage}
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default SigninConfirm;