import { ReactElement, useState, PropsWithChildren } from "react";

interface ErrorPageProps {
  children: ReactElement | ReactElement[];
}

export default function ErrorPage({ children }: PropsWithChildren<ErrorPageProps>) {
  const [hasError, setError] = useState<boolean>(false);

  return (
    <>
      {hasError ? (
        <div className="container mx-auto text-center">
          <h3 className="text-lg">Uh Oh!...Error 500!</h3>
          <span className="text-sm">
            Something went wrong at our end. Don't worry, it's not you, it's us.
            Sorry about that.
          </span>
          <button 
            className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
            onClick={() => window.location.href = "/"}>
            Go Back To Homepage
          </button>
        </div>
      ) : (
        children
      )}
    </>
  );
}