import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { doctorService } from '../../api/services/doctorService';
import {  ConsultationResponse } from '../../types';


interface Visit {
  id: number;
  patientId: number;
  doctorId: number;
  clinicId: number;
  date: string;
  status: string;
  // Add other visit properties as needed
}
export interface DoctorState {
  visits: Visit[];
  currentVisits: Visit[];
  myConsultations: ConsultationResponse[];
  loading: boolean;
  error: string | null;
}

const initialState: DoctorState = {
  visits: [],
  currentVisits: [],
  loading: false,
  error: null,
  myConsultations: [],
};

// Async thunks
export const getMyConsultations = createAsyncThunk(
  'doctor/getMyConsultations',
  async (docId: number) => {
    const response = await doctorService.getMyConsultations(docId);
    return response.data;
  }
);
export const getVisitsByDoctor = createAsyncThunk(
  'doctor/getVisitsByDoctor',
  async (docId: number) => {
    const response = await doctorService.getVisitsByDoctor(docId);
    return response.data;
  }
);

export const getVisitsByClinic = createAsyncThunk(
  'doctor/getVisitsByClinic',
  async (clinicId: number) => {
    const response = await doctorService.getVisitsByClinic(clinicId);
    return response.data;
  }
);

export const getVisitsByPatient = createAsyncThunk(
  'doctor/getVisitsByPatient',
  async (patientId: number) => {
    const response = await doctorService.getVisitsByPatient(patientId);
    return response.data;
  }
);

export const doctorSlice = createSlice({
  name: 'doctor',
  initialState,
  reducers: {
    clearVisits: (state) => {
      state.visits = [];
      state.currentVisits = [];
    },
    setCurrentVisits: (state, action) => {
      state.currentVisits = action.payload;
    },
    clearMyConsultations: (state) => {
      state.myConsultations = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // Get Visits by Doctor
      .addCase(getVisitsByDoctor.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVisitsByDoctor.fulfilled, (state, action) => {
        state.loading = false;
        state.visits = action.payload;
        state.currentVisits = action.payload;
      })
      .addCase(getVisitsByDoctor.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch doctor visits';
      })
      // Get Visits by Clinic
      .addCase(getVisitsByClinic.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVisitsByClinic.fulfilled, (state, action) => {
        state.loading = false;
        state.visits = action.payload;
      })
      .addCase(getVisitsByClinic.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch clinic visits';
      })
      // Get Visits by Patient
      .addCase(getVisitsByPatient.fulfilled, (state, action) => {
        state.currentVisits = action.payload;
      })
      .addCase(getMyConsultations.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMyConsultations.fulfilled, (state, action) => {
        state.loading = false;
        state.myConsultations = action.payload;
      })
      .addCase(getMyConsultations.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch consultations';
      });
  },
});

export const { clearVisits, setCurrentVisits } = doctorSlice.actions;

// Selectors
export const selectDoctor = (state: { doctor: DoctorState }) => state.doctor;
export const selectVisits = (state: { doctor: DoctorState }) => state.doctor.visits;
export const selectCurrentVisits = (state: { doctor: DoctorState }) => state.doctor.currentVisits;
export const selectDoctorLoading = (state: { doctor: DoctorState }) => state.doctor.loading;
export const selectDoctorError = (state: { doctor: DoctorState }) => state.doctor.error;
export const selectMyConsultations = (state: { doctor: DoctorState }) => 
  state.doctor.myConsultations;


 
 
 

 
export default doctorSlice.reducer;