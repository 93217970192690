import React from 'react';
import { Paper, Typography, Box, Divider } from '@mui/material';
import { ConsultationResponse } from '../../../../types';
import PatientDetails from './PatientDetails';
import VisitInformation from './VisitInformation';
import ClinicInformation from './ClinicInformation';

interface ConsultationDetailProps {
    consultation: ConsultationResponse | null;
}

const ConsultationDetail: React.FC<ConsultationDetailProps> = ({ consultation }) => {
    if (!consultation) {
        return (
            <Paper sx={{ p: 3, height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant="h6">Select a consultation to view details</Typography>
            </Paper>
        );
    }

    return (
        <Paper sx={{ p: 3, height: '100%', overflow: 'auto' }}>
            <Typography variant="h5" gutterBottom>
                Patient Details
            </Typography>
            <PatientDetails patient={consultation.patient} />

            <Divider sx={{ my: 2 }} />

            <Typography variant="h5" gutterBottom>
                Visit Information
            </Typography>
            <VisitInformation visit={consultation.visit} />

            <Divider sx={{ my: 2 }} />

            <Typography variant="h5" gutterBottom>
                Clinic Information
            </Typography>
            <ClinicInformation clinic={consultation.clinic} />
        </Paper>
    );
};

export default ConsultationDetail;