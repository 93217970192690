import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { displayService } from '../../api/services/displayService';
import { DisplayState, DisplayResponse } from '../../api/types';

const initialState: DisplayState = {
    title: '',
    tokens: [],
    missedToken: null,
    note: '',
    image: '',
    imageDetail: '',
    loading: false,
    error: null,
    lastUpdated: null,
};

export const getTokenStatus = createAsyncThunk(
    'display/getTokenStatus',
    async (clinicId: number) => {
        const response = await displayService.getTokenStatus(clinicId);
        return response.data as DisplayResponse;
    }
);

export const displaySlice = createSlice({
    name: 'display',
    initialState,
    reducers: {
        clearDisplayData: (state) => {
            return { ...initialState };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getTokenStatus.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getTokenStatus.fulfilled, (state, action) => {
                state.loading = false;
                state.title = action.payload.title;
                state.tokens = action.payload.tokenList;
                state.missedToken = action.payload.missedToken;
                state.note = action.payload.note;
                state.image = action.payload.image;
                state.imageDetail = action.payload.imageDetail;
                state.lastUpdated = new Date().toISOString();
            })
            .addCase(getTokenStatus.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch token status';
            });
    },
});

export const { clearDisplayData } = displaySlice.actions;

// Selectors
export const selectDisplay = (state: { display: DisplayState }) => state.display;
export const selectTitle = (state: { display: DisplayState }) => state.display.title;
export const selectTokens = (state: { display: DisplayState }) => state.display.tokens;
export const selectNote = (state: { display: DisplayState }) => state.display.note;
export const selectDisplayLoading = (state: { display: DisplayState }) => state.display.loading;
export const selectDisplayError = (state: { display: DisplayState }) => state.display.error;
export const selectMissedToken = (state: { display: DisplayState }) => state.display.missedToken;

export default displaySlice.reducer;