import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { store, persistor } from './store';
import { theme } from './theme';
import Counter from './components/Counter';
import Posts from './components/Posts';
import Layout from './components/Layout';
import ErrorPage from "./pages/error/ErrorPage";
import { BrowserRouter } from "react-router-dom";
import BasicRoutes from "./routes/index";

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {/* <Layout>
            <Counter />
            <Posts />
          </Layout> */}
          <BrowserRouter>
            <ErrorPage>
              <BasicRoutes />
            </ErrorPage>
          </BrowserRouter>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
