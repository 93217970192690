import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { patientService } from '../../api/services/patientService';
import { UPatientInfo } from '../../api/types';

interface PatientState {
  patients: UPatientInfo[];
  currentPatient: UPatientInfo | null;
  loading: boolean;
  error: string | null;
  registrationSuccess: boolean;
}

const initialState: PatientState = {
  patients: [],
  currentPatient: null,
  loading: false,
  error: null,
  registrationSuccess: false,
};

// Async thunks
export const registerPatient = createAsyncThunk(
  'patient/register',
  async (patientInfo: UPatientInfo) => {
    const response = await patientService.register(patientInfo);
    return response.data;
  }
);

export const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    setCurrentPatient: (state, action) => {
      state.currentPatient = action.payload;
    },
    clearPatientData: (state) => {
      state.currentPatient = null;
      state.registrationSuccess = false;
    },
    resetRegistrationStatus: (state) => {
      state.registrationSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerPatient.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(registerPatient.fulfilled, (state, action) => {
        state.loading = false;
        state.patients.push(action.payload);
        state.currentPatient = action.payload;
        state.registrationSuccess = true;
      })
      .addCase(registerPatient.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to register patient';
        state.registrationSuccess = false;
      });
  },
});

export const { 
  setCurrentPatient, 
  clearPatientData,
  resetRegistrationStatus 
} = patientSlice.actions;

// Selectors
export const selectPatient = (state: { patient: PatientState }) => state.patient;
export const selectPatients = (state: { patient: PatientState }) => state.patient.patients;
export const selectCurrentPatient = (state: { patient: PatientState }) => state.patient.currentPatient;
export const selectPatientLoading = (state: { patient: PatientState }) => state.patient.loading;
export const selectPatientError = (state: { patient: PatientState }) => state.patient.error;
export const selectRegistrationSuccess = (state: { patient: PatientState }) => 
  state.patient.registrationSuccess;

export default patientSlice.reducer;