// src/services/patientService.ts

import { AxiosResponse } from 'axios';
import axiosInstance from '../axios';
import { API_ENDPOINTS } from '../endpoints';
import { UPatientInfo } from '../types';

export const patientService = {
  register: (patientInfo: UPatientInfo): Promise<AxiosResponse<any>> => {
    return axiosInstance.post(API_ENDPOINTS.PATIENT.REGISTER, patientInfo);
  },
};
