import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Divider,
  Box,
  styled,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  Queue as QueueIcon,
  Person as PersonIcon,
  Search as SearchIcon,
  History as HistoryIcon,
  Call as CallIcon,
  Logout as LogoutIcon,
} from '@mui/icons-material';
import { logoutSuccess } from '../../store/slices/authSlice';
import { useDispatch } from 'react-redux';

const drawerWidthDesktop = 240;
const drawerWidthMobile = 72;

type NavigationRoute = 'dashboard' | 'queue' | 'profile' | 'consultation' | 'search' | 'call' | 'logout';

interface NavItem {
  id: NavigationRoute;
  label: string;
  icon: React.ReactNode;
  route: string;
}

const StyledDrawer = styled(Drawer)<{ isMobile: boolean }>(({ theme, isMobile }) => ({
  width: isMobile ? drawerWidthMobile : drawerWidthDesktop,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: isMobile ? drawerWidthMobile : drawerWidthDesktop,
    boxSizing: 'border-box',
    backgroundColor: '#143180',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
  },
}));

const StyledListItemButton = styled(ListItemButton)<{ isMobile: boolean }>(({ isMobile }) => ({
  margin: isMobile ? '8px 4px' : '8px 16px',
  borderRadius: '8px',
  justifyContent: isMobile ? 'center' : 'flex-start',
  padding: isMobile ? '12px 0' : '8px 16px',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
  '&.Mui-selected': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.25)',
    },
  },
  '& .MuiListItemIcon-root': {
    color: '#fff',
    minWidth: isMobile ? '24px' : '40px',
    marginRight: isMobile ? '0' : '8px',
  },
  '& .MuiListItemText-primary': {
    fontWeight: 500,
    fontSize: '0.95rem',
  },
}));

const StyledDivider = styled(Divider)({
  backgroundColor: 'rgba(255, 255, 255, 0.12)',
});

const NavigationDrawer: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const [selectedItem, setSelectedItem] = useState<NavigationRoute>('dashboard');

  const navItems: NavItem[] = [
    { id: 'dashboard', label: 'Dashboard', icon: <QueueIcon />, route: '/dashboard' },
    { id: 'profile', label: 'Profile', icon: <PersonIcon />, route: '/profile' },
  ];

  useEffect(() => {
    const path = location.pathname.split('/')[2] || 'dashboard';
    setSelectedItem(path as NavigationRoute);
  }, [location]);

  const handleNavigation = (route: NavigationRoute): void => {
    setSelectedItem(route);
    navigate(`/reception/${route}`);  // Keep reception-specific routing
  };

  const handleLogout = async () => {
    try {
      await dispatch(logoutSuccess());
      navigate('/');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <StyledDrawer variant="permanent" isMobile={isMobile}>
      <List sx={{ mt: 2, flex: '1 0 auto' }}>
        {navItems.map((item) => (
          <StyledListItemButton
            key={item.id}
            selected={selectedItem === item.id}
            onClick={() => handleNavigation(item.id)}
            isMobile={isMobile}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            {!isMobile && <ListItemText primary={item.label} />}
          </StyledListItemButton>
        ))}
      </List>

      <Box sx={{ mt: 'auto' }}>
        <StyledDivider />
        <StyledListItemButton
          onClick={handleLogout}
          isMobile={isMobile}
          sx={{ 
            mt: '16px',
            position: 'relative',
            bottom: '16px',
            width: isMobile ? 'calc(100% - 8px)' : 'calc(100% - 32px)',
          }}
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          {!isMobile && <ListItemText primary="Logout" />}
        </StyledListItemButton>
      </Box>
    </StyledDrawer>
  );
};

export default NavigationDrawer;