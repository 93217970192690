import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { businessService } from '../../api/services/businessService';
import { ClinicInfo, DoctorInfo, ClinicRoom } from '../../api/types';

interface BusinessState {
  clinics: ClinicInfo[];
  doctors: DoctorInfo[];
  rooms: ClinicRoom[];
  loading: boolean;
  error: string | null;
  activeClinicId: number | null;
  clinicActivationStatus: {
    [key: number]: boolean;
  };
  successMessage: string | null;
}

const initialState: BusinessState = {
  clinics: [],
  doctors: [],
  rooms: [],
  loading: false,
  error: null,
  activeClinicId: null,
  clinicActivationStatus: {},
  successMessage: null,
};

// Async thunks
export const getAllClinics = createAsyncThunk(
  'business/getAllClinics',
  async () => {
    const response = await businessService.getAllClinics();
    return response.data;
  }
);

export const setClinicActive = createAsyncThunk(
  'business/setClinicActive',
  async ({ clinicId, toEnable }: { clinicId: number; toEnable: boolean }) => {
    const response = await businessService.setClinicActive(clinicId, toEnable);
    return { clinicId, toEnable, data: response.data };
  }
);

export const addSpecialityDoctor = createAsyncThunk(
  'business/addSpecialityDoctor',
  async (doctorInfo: DoctorInfo) => {
    const response = await businessService.addSpecialityDoctor(doctorInfo);
    return response.data;
  }
);

export const addRoom = createAsyncThunk(
  'business/addRoom',
  async (room: ClinicRoom) => {
    const response = await businessService.addRoom(room);
    return response.data;
  }
);

export const businessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    clearBusinessData: (state) => {
      state.clinics = [];
      state.doctors = [];
      state.rooms = [];
      state.activeClinicId = null;
      state.clinicActivationStatus = {};
      state.successMessage = null;
      state.error = null;
    },
    setActiveClinic: (state, action) => {
      state.activeClinicId = action.payload;
    },
    updateClinicStatus: (state, action) => {
      const { clinicId, status } = action.payload;
      state.clinicActivationStatus[clinicId] = status;
    },
    clearSuccess: (state) => {
      state.successMessage = null;
    },
    clearError: (state) => {
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      // Get All Clinics
      .addCase(getAllClinics.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllClinics.fulfilled, (state, action) => {
        state.loading = false;
        state.clinics = action.payload;
        // Initialize activation status for all clinics
        action.payload.forEach((clinic: ClinicInfo) => {
          if (clinic.regNo) {
            state.clinicActivationStatus[parseInt(clinic.regNo)] = true;
          }
        });
      })
      .addCase(getAllClinics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch clinics';
      })

      // Set Clinic Active Status
      .addCase(setClinicActive.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(setClinicActive.fulfilled, (state, action) => {
        state.loading = false;
        state.clinicActivationStatus[action.payload.clinicId] = action.payload.toEnable;
        state.successMessage = `Clinic ${action.payload.toEnable ? 'activated' : 'deactivated'} successfully`;
      })
      .addCase(setClinicActive.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to update clinic status';
      })

      // Add Speciality Doctor
      .addCase(addSpecialityDoctor.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addSpecialityDoctor.fulfilled, (state, action) => {
        state.loading = false;
        state.doctors.push(action.payload);
        state.successMessage = 'Doctor added successfully';
      })
      .addCase(addSpecialityDoctor.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to add doctor';
      })

      // Add Room
      .addCase(addRoom.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addRoom.fulfilled, (state, action) => {
        state.loading = false;
        state.rooms.push(action.payload);
        state.successMessage = 'Room added successfully';
      })
      .addCase(addRoom.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to add room';
      });
  },
});

// Export actions
export const {
  clearBusinessData,
  setActiveClinic,
  updateClinicStatus,
  clearSuccess,
  clearError
} = businessSlice.actions;

// Export selectors
export const selectBusiness = (state: { business: BusinessState }) => state.business;
export const selectClinics = (state: { business: BusinessState }) => state.business.clinics;
export const selectBusinessDoctors = (state: { business: BusinessState }) => state.business.doctors;
export const selectBusinessRooms = (state: { business: BusinessState }) => state.business.rooms;
export const selectBusinessLoading = (state: { business: BusinessState }) => state.business.loading;
export const selectBusinessError = (state: { business: BusinessState }) => state.business.error;
export const selectActiveClinicId = (state: { business: BusinessState }) => state.business.activeClinicId;
export const selectClinicActivationStatus = (state: { business: BusinessState }, clinicId: number) => 
  state.business.clinicActivationStatus[clinicId];
export const selectSuccessMessage = (state: { business: BusinessState }) => state.business.successMessage;

// Utility selectors
export const selectActiveClinic = (state: { business: BusinessState }) => {
  const activeId = state.business.activeClinicId;
  return activeId ? state.business.clinics.find(clinic => clinic.regNo === activeId.toString()) : null;
};

export const selectActiveClinics = (state: { business: BusinessState }) => {
  return state.business.clinics.filter(clinic => 
    clinic.regNo && state.business.clinicActivationStatus[parseInt(clinic.regNo)]
  );
};

export default businessSlice.reducer;