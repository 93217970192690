import React from 'react';
import { Box, Typography } from '@mui/material';
import { Clinic} from '../../../../types';

interface ClinicInformationProps {
    clinic: Clinic;
}

const ClinicInformation: React.FC<ClinicInformationProps> = ({ clinic }) => {
    return (
        <Box>
            <Typography variant="body1" fontWeight="bold">
                {clinic.name}
            </Typography>
            <Typography variant="body1">
                {clinic.address}
            </Typography>
            <Typography variant="body1">
                Contact: {clinic.contact}
            </Typography>
            <Typography variant="body1">
                Email: {clinic.email}
            </Typography>
            <Typography variant="body1">
                Registration No: {clinic.regNo}
            </Typography>
        </Box>
    );
};

export default ClinicInformation;