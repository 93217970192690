import React, { useState, FormEvent } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from '../../store';
import { useAppSelector, useAppDispatch } from '../../hooks/redux';
import {
  Grid,
  Box,
  Typography,
  Button,
  Paper,
} from "@mui/material";
import { auth, signInWithGooglePopup } from "../../firebase";
import { sendSignInLinkToEmail } from "firebase/auth";
import googleIcon from "../../assets/google.svg";
import { loginSuccess, selectIsLoggedIn, selectUserRole } from '../../store/slices/authSlice';

interface GoogleAuthResponse {
  user: {
    email: string;
    uid: string;
    [key: string]: any;
  };
  [key: string]: any;
}

const PatientLogin: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [emailsend, setEmailSend] = useState<boolean>(false);
  const [notice, setNotice] = useState<string>("");

  // const userRole = useAppSelector((state) => state.auth.role);
  // const isLoggedIn = useAppSelector((state) => state.auth.loggedIn);
  const { posts, selectedPost, selectedPostComments } = useSelector(
    (state: RootState) => state.posts
  );
  // console.log('posts', posts)
  const { loggedIn, role, userType } = useSelector(
    (state: RootState) => state.auth
  );
  console.log('posts', loggedIn, role)
  const handleRedirect = (): void => {
    navigate('/patient/dashboard');
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const email = formData.get("email") as string;
    const actionCodeSettings = {
      url: 'https://clinic.tap2share.co/patient/signin-confirm',
      handleCodeInApp: true,
    };

    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem("emailForSignIn", email);
      window.localStorage.setItem("userType", "patient");
      const params = new URLSearchParams(location?.search);
      const redirectPath = params.get('redirect');
      window.localStorage.setItem("redirect", "/patient/dashboard");
      setEmailSend(true);
    } catch (error) {
      setNotice("Error sending login link.");
    }
  };

  const logGoogleUser = async (): Promise<void> => {
    try {
      const response = await signInWithGooglePopup();

      await dispatch(loginSuccess({
        ...response,
        userType: 'patient'
      }));
      await window.localStorage?.setItem('authUser', JSON.stringify({
        ...response,
        userType: 'patient'
      }));

      handleRedirect();
    } catch {
      setNotice("Login failed.");
    }
  };

  return (
    <Grid
      container
      sx={{
        height: "calc(100vh - 120px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Grid item xs={12} md={6}>
        <Paper
          elevation={3}
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 3
          }}
        >
          <Box sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}>
            {!emailsend ? (
              <Box
                sx={{
                  my: 8,
                  mx: 4,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleSubmit}
                  sx={{
                    mt: { xs: 2, sm: 3 },
                    width: { xs: '90%', sm: '400px', md: '500px' },
                    maxWidth: '500px',
                    mx: 'auto',
                    px: { xs: 2, sm: 0 }
                  }}
                >
                  <Box sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    width: "100%",
                    mb: 1,
                  }}>
                    <Typography
                      component="h1"
                      variant="h5"
                      sx={{
                        fontFamily: "Satoshi-Regular",
                        fontSize: "32px",
                        color: "#12190F",
                        fontWeight: "700",
                        letterSpacing: "0.96px",
                      }}
                    >
                      Patient Login
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        pt: 1,
                        color: "#818181",
                        fontSize: "14px",
                        lineHeight: "24px",
                      }}
                    >
                      Enter your email Id -- {loggedIn} {userType}
                    </Typography>
                  </Box>

                  <Box sx={{
                    pt: 2,
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: "100%",
                  }}>
                    <label htmlFor="email">Email ID</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Enter your email"
                      required
                      style={{
                        width: '100%',
                        padding: '16px 20px',
                        margin: '8px 0',
                        borderRadius: '6px',
                        border: '1px solid #E2E8F0'
                      }}
                    />
                  </Box>

                  {notice && (
                    <Typography color="error" sx={{ mt: 1 }}>
                      {notice}
                    </Typography>
                  )}

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    sx={{
                      mt: 3,
                      mb: 2,
                      height: "52px",
                      textTransform: "none",
                      fontSize: "18px",
                    }}
                  >
                    Next
                  </Button>

                  <Box sx={{
                    mt: 2,
                    mb: 2,
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "18px",
                  }}>
                    Or
                  </Box>

                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={logGoogleUser}
                    sx={{
                      mt: 1,
                      mb: 1,
                      color: "#1E293D",
                      fontSize: "16px",
                      height: "52px",
                      textTransform: "none",
                      borderWidth: "2px",
                      borderColor: "#E2E8F0",
                      borderRadius: "8px",
                    }}
                    startIcon={<img src={googleIcon} height="22px" style={{ paddingRight: "4px" }} alt="Google Icon" />}
                  >
                    Sign In with Google
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box sx={{
                textAlign: "center",
                maxWidth: "500px",
                p: 3
              }}>
                <Typography
                  variant="h4"
                  sx={{
                    color: "#12190F",
                    fontSize: "30px",
                    fontWeight: "700",
                    mb: 2
                  }}
                >
                  Access Link Sent to Your Email
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#ABABAB",
                    fontSize: "18px",
                    fontWeight: "500"
                  }}
                >
                  A secure access link has been sent to your email address. Please check your inbox and click the link to log in.
                </Typography>
              </Box>
            )}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PatientLogin;