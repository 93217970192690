import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import IndexPage from "../pages/indexPage/indexPage";
import PatientLogin from "../pages/patientLogin/PatientLogin";
import HealthcareLogin from "../pages/healthLogin/HealthLogin";
import DoctorDashboard from "../pages/doc/DrDashboard";
import DoctorProfile from "../pages/doc/DoctorProfile";
import DoctorConsultation from "../pages/doc/DoctorConsultation";
import DoctorSearch from "../pages/doc/DoctorSearch";
import DoctorCall from "../pages/doc/DoctorCall";

import SigninConfirm from "../pages/healthLogin/SigninConfirm";
import SigninConfirmPatient from "../pages/patientLogin/SigninConfirm";


// Add these imports along with the existing ones
import PatientDashboard from "../pages/patient/patientDashboard";
import PatientProfile from "../pages/patient/patientProfile";
import AdminDashboard from "../pages/admin/adminDashboard";
import AdminProfile from "../pages/admin/adminProfile";
import ReceptionDashboard from "../pages/reception/receptionDashboard";
import ReceptionProfile from "../pages/reception/receptionProfile";
// import UserProfiles from '../pages/userProfiles/UserProfiles';
// import LoginPage from "../pages/login/LoginPage";
// import SignUpPage from "../pages/signup/SignUpPage";
// import ClinicDashboard from '../pages/dashboard/dashboard';
// import DoctorDashboard from "../pages/dashboard/doctorDashboard";
// import DrDashboard from "../pages/dashboard/DrDashboard";
// import PatientDashboard from "../pages/patientDashboard/PatientDashboard";



const BasicRoutes: React.FC = () => {


    return (
        <Routes>
            <Route path="/" element={<IndexPage />} />
            <Route path="/patient-login" element={<PatientLogin />} />
            <Route path="/healthcare-login" element={<HealthcareLogin />} />
            <Route path="/dr/dashboard" element={<DoctorDashboard />} />
            <Route path="/dr/profile" element={<DoctorProfile />} />
            <Route path="/dr/consultation" element={<DoctorConsultation />} />
            <Route path="/dr/search" element={<DoctorSearch />} />
            <Route path="/dr/call" element={<DoctorCall />} />
            <Route path="/healthcare/signin-confirm" element={<SigninConfirm />} />
            <Route path="/patient/signin-confirm" element={<SigninConfirmPatient />} />

            {/* Patient routes */}
            <Route path="/patient/dashboard" element={<PatientDashboard />} />
            <Route path="/patient/profile" element={<PatientProfile />} />

            {/* Admin routes */}
            <Route path="/admin/dashboard" element={<AdminDashboard />} />
            <Route path="/admin/profile" element={<AdminProfile />} />

            {/* Reception routes */}
            <Route path="/reception/dashboard" element={<ReceptionDashboard />} />
            <Route path="/reception/profile" element={<ReceptionProfile />} />
            {/*
            <Route path="/signin-confirm" element={<SigninConfirm />} />
            <Route path="/register" element={<UserProfiles />} />
            <Route path="/queue/:clinicId" element={<ClinicDashboard />} />
            <Route path="/dr/queue" element={<DoctorDashboard />} />
            <Route path="/clinic/:clinicId/register" element={<UserProfiles />} />
            <Route
                path="/login"
                element={<LoginPage />}
            />
            <Route path="/signup" element={<SignUpPage />} />
            <Route path="/dr/dashboard" element={<DrDashboard />} />
            <Route path="/patient/dashboard" element={<PatientDashboard />} /> */}
        </Routes>
    );
};

export default BasicRoutes;