import React from 'react';
import { Box, Typography } from '@mui/material';
import { Visit } from '../../../../types/index';

interface VisitInformationProps {
    visit: Visit;
}

const VisitInformation: React.FC<VisitInformationProps> = ({ visit }) => {
    return (
        <Box sx={{ mb: 3 }}>
            <Typography variant="body1">
                Token: {visit.token}
            </Typography>
            <Typography variant="body1">
                Date: {new Date(visit.visitDate).toLocaleString()}
            </Typography>
            <Typography variant="body1">
                Status: {visit.status}
            </Typography>
            <Typography variant="body1">
                Symptoms: {visit.symptoms || 'Not provided'}
            </Typography>
            <Typography variant="body1">
                Vitals: {visit.vitals || 'Not recorded'}
            </Typography>
            {visit.diagnosis && (
                <Typography variant="body1">
                    Diagnosis: {visit.diagnosis}
                </Typography>
            )}
            {visit.prescription && (
                <Typography variant="body1">
                    Prescription: {visit.prescription}
                </Typography>
            )}
        </Box>
    );
};

export default VisitInformation;