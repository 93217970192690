// src/services/displayService.ts

import { AxiosResponse } from 'axios';
import axiosInstance from '../axios';
import { API_ENDPOINTS } from '../endpoints';

export const displayService = {
  getTokenStatus: (clinicId: number): Promise<AxiosResponse<any>> => {
    return axiosInstance.get(API_ENDPOINTS.DISPLAY.GET_TOKEN_STATUS, {
      params: { clinicId },
    });
  },
};