import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { 
    getTokenStatus, 
    selectTitle,
    selectTokens, 
    selectNote,
    selectDisplayLoading, 
    selectDisplayError,
    selectMissedToken
} from '../../../store/slices/displaySlice';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    CircularProgress,
    useTheme,
    useMediaQuery,
    Alert
} from '@mui/material';

const QueueDisplay: React.FC = () => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    
    // Redux state
    const title = useAppSelector(selectTitle);
    const tokens = useAppSelector(selectTokens);
    const note = useAppSelector(selectNote);
    const loading = useAppSelector(selectDisplayLoading);
    const error = useAppSelector(selectDisplayError);
    const missedToken = useAppSelector(selectMissedToken);

    useEffect(() => {
        const fetchTokens = () => {
            const urlParams = new URLSearchParams(window.location.search);
            const clinicId = parseInt(urlParams.get('cl') || '1');
            dispatch(getTokenStatus(clinicId));
        };

        fetchTokens();
        const intervalId = setInterval(fetchTokens, 30000); // Refresh every 30 seconds
        return () => clearInterval(intervalId);
    }, [dispatch]);

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ p: 3, textAlign: 'center' }}>
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ width: '100%', maxWidth: 800, mx: 'auto', p: 2 }}>
            <Typography variant="h5" gutterBottom align="center">
                {title}
            </Typography>

            {note && (
                <Alert severity="info" sx={{ mb: 2 }}>
                    {note}
                </Alert>
            )}

            {missedToken && (
                <Alert severity="warning" sx={{ mb: 2 }}>
                    Missed Token: {missedToken}
                </Alert>
            )}

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Token No.</TableCell>
                            {!isSmallScreen && <TableCell>Room</TableCell>}
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tokens.map((token) => (
                            <TableRow 
                                key={token.queueNo}
                                sx={token.blinkForSecs > 0 ? {
                                    animation: 'blink 1s linear infinite',
                                    '@keyframes blink': {
                                        '50%': {
                                            backgroundColor: 'rgba(144, 202, 249, 0.2)',
                                        },
                                    },
                                } : {}}
                            >
                                <TableCell>{token.queueNo}</TableCell>
                                {!isSmallScreen && <TableCell>{token.room || '-'}</TableCell>}
                                <TableCell>{token.status}</TableCell>
                            </TableRow>
                        ))}
                        {tokens.length === 0 && (
                            <TableRow>
                                <TableCell 
                                    colSpan={isSmallScreen ? 2 : 3} 
                                    align="center"
                                >
                                    <Typography variant="body2" sx={{ py: 2 }}>
                                        No tokens available
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default QueueDisplay;