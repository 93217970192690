export const API_ENDPOINTS = {
    AUTH: {
      LOGIN: '/auth/login',
      REGISTER: '/auth/register',
      REFRESH: '/auth/refresh',
    },
    USERS: {
      GET_ALL: '/users',
      GET_BY_ID: (id: string) => `/users/${id}`,
      UPDATE: (id: string) => `/users/${id}`,
      DELETE: (id: string) => `/users/${id}`,
    },
    POSTS: {
      GET_ALL: '/posts',
      GET_BY_ID: (id: number) => `/posts/${id}`,
      CREATE: '/posts',
      UPDATE: (id: number) => `/posts/${id}`,
      DELETE: (id: number) => `/posts/${id}`,
      GET_BY_USER: (userId: number) => `/users/${userId}/posts`,
      GET_COMMENTS: (postId: number) => `/posts/${postId}/comments`,
    },
    RECEPTION: {
      REGISTER_PATIENT: '/recep/register-patient',
      OPEN_CLINIC: '/recep/open-clinic',
    },
    PATIENT: {
      REGISTER: '/patient/register',
    },
    NOTIFICATION: {
      SEND_MESSAGE: '/notif/message',
    },
    CLINIC: {
      LINK_DOCTOR: '/clinic/link-clinic-doctor',
      CREATE_ADD_DOCTOR: '/clinic/create-add-doctor',
      ADD_SPECIALITY_DOCTOR: '/clinic/add-speciality-doc',
      ADD_ROOM: '/clinic/add-room',
      ADD_RECEPTION: '/clinic/add-reception',
      ADD_CLINIC: '/clinic/add-clinic',
      GET_REGISTER_INFO: '/clinic/register-info',
      GET_CLINIC: '/clinic/get-clinic',
      FIND_DOCTOR: '/clinic/find-doc',
    },
    BUSINESS: {
      SET_CLINIC_ACTIVE: '/buss/set-clinic-active',
      ADD_SPECIALITY_DOCTOR: '/buss/add-speciality-doc',
      ADD_ROOM: '/buss/add-room',
      GET_REGISTER_INFO: '/buss/register-info',
      GET_ALL_CLINICS: '/buss/get-all-clinic',
    },
    DOCTOR: {
      GET_VISITS_BY_PATIENT: '/doc/get-visit-by-patient',
      GET_VISITS_BY_DOCTOR: '/doc/get-visit-by-doctor',
      GET_VISITS_BY_CLINIC: '/doc/get-visit-by-clinic',
      GET_MY_CONSULTATIONS: (docId: number) => `/doc/my-consultation?docId=${docId}`,
    },
    DISPLAY: {
      GET_TOKEN_STATUS: '/display/token-status',
    },
  };