import React, { useState, FormEvent, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  Grid,
  Box,
  Typography,
  Button,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  CircularProgress,
  Alert,
  Snackbar,
  Paper
} from "@mui/material";
import { auth, signInWithGooglePopup } from "../../firebase";
import { sendSignInLinkToEmail } from "firebase/auth";
import { loginSuccess, selectIsLoggedIn, selectUserRole } from '../../store/slices/authSlice';
import googleIcon from "../../assets/google.svg";

type UserRole = 'admin' | 'doctor' | 'reception';

interface SnackbarConfig {
  vertical: 'top' | 'bottom';
  horizontal: 'left' | 'center' | 'right';
}

const HealthcareLogin: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [emailsend, setEmailSend] = useState<boolean>(false);
  const [notice, setNotice] = useState<string>("");
  const [role, setRole] = useState<UserRole>("doctor");
  const [loading, setLoading] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const handleRedirect = (userRole: UserRole): void => {
    switch(userRole) {
      case 'admin':
        navigate('/admin/dashboard');
        break;
      case 'doctor':
        navigate('/dr/dashboard');
        break;
      case 'reception':
        navigate('/reception/dashboard');
        break;
      default:
        navigate('/dr/dashboard');
    }
  };

  const handleCloseSnackbar = (): void => {
    setOpenSnackbar(false);
  };

  const handleRoleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setRole(event.target.value as UserRole);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData(event.currentTarget);
    const email = formData.get("email") as string;

    if (!email) {
      setNotice("Please enter your email address");
      setOpenSnackbar(true);
      setLoading(false);
      return;
    }

    const actionCodeSettings = {
      url: 'https://clinic.tap2share.co/healthcare/signin-confirm',
      handleCodeInApp: true,
    };

    try {
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      window.localStorage.setItem("emailForSignIn", email);
      window.localStorage.setItem("userRole", role);
      window.localStorage.setItem("userType", "healthcare");
      
      let redirectPath;
      switch(role) {
        case 'admin':
          redirectPath = '/admin/dashboard';
          break;
        case 'doctor':
          redirectPath = '/dr/dashboard';
          break;
        case 'reception':
          redirectPath = '/reception/dashboard';
          break;
        default:
          redirectPath = '/dr/dashboard';
      }
      
      window.localStorage.setItem("redirect", redirectPath);
      setEmailSend(true);
    } catch (error) {
      setNotice( "Error sending login link.");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const logGoogleUser = async (): Promise<void> => {
    // Google login implementation
    try {
      setLoading(true);
      const response = await signInWithGooglePopup();
      
      // Store user data in Redux
      await dispatch(loginSuccess({
        ...response,
        userType: 'healthcare',
        role: role
      }));
      
      // Store in localStorage
      await window.localStorage?.setItem('authUser', JSON.stringify({
        ...response,
        userType: 'healthcare',
        role: role
      }));
      
      handleRedirect(role);
    } catch (error) {
      setNotice( "Login failed.");
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const snackbarPosition: SnackbarConfig = {
    vertical: 'top',
    horizontal: 'right'
  };

  return (
    <Grid 
      container 
      sx={{ 
        height: "calc(100vh - 120px)", 
        display: "flex", 
        alignItems: "center", 
        justifyContent: "center" 
      }}
    >
      <Grid item xs={12} md={6}>
        <Paper 
          elevation={3}
          sx={{
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 3
          }}
        >
          {!emailsend ? (
            <Box sx={{
              width: { xs: '90%', sm: '400px', md: '500px' },
              maxWidth: '500px',
              mx: 'auto',
              px: { xs: 2, sm: 0 }
            }}>
              <Typography variant="h5" sx={{
                fontFamily: "Satoshi-Regular",
                fontSize: "32px",
                color: "#12190F",
                fontWeight: "700",
                mb: 2
              }}>
                Healthcare Provider Login
              </Typography>

              <FormControl sx={{ mb: 3, width: '100%' }}>
                <Typography sx={{ mb: 1, color: '#12190F' }}>Select Role</Typography>
                <RadioGroup
                  row
                  value={role}
                  onChange={handleRoleChange}
                >
                  <FormControlLabel 
                    value="admin" 
                    control={<Radio />} 
                    label="Admin"
                    sx={{ 
                      '& .MuiFormControlLabel-label': { 
                        color: '#12190F',
                        fontSize: '16px'
                      }
                    }}
                  />
                  <FormControlLabel 
                    value="doctor" 
                    control={<Radio />} 
                    label="Doctor"
                    sx={{ 
                      '& .MuiFormControlLabel-label': { 
                        color: '#12190F',
                        fontSize: '16px'
                      }
                    }}
                  />
                  <FormControlLabel 
                    value="reception" 
                    control={<Radio />} 
                    label="Reception"
                    sx={{ 
                      '& .MuiFormControlLabel-label': { 
                        color: '#12190F',
                        fontSize: '16px'
                      }
                    }}
                  />
                </RadioGroup>
              </FormControl>
              
              <Box component="form" onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <label htmlFor="email">Email ID</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter your email"
                    required
                    style={{
                      width: '100%',
                      padding: '16px 20px',
                      margin: '8px 0',
                      borderRadius: '6px',
                      border: '1px solid #E2E8F0'
                    }}
                  />
                </Box>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  disabled={loading}
                  sx={{
                    height: "52px",
                    textTransform: "none",
                    fontSize: "18px",
                    mb: 2
                  }}
                >
                  {loading ? <CircularProgress size={24} /> : 'Next'}
                </Button>

                <Box sx={{ textAlign: "center", my: 2 }}>Or</Box>

                <Button
                  variant="outlined"
                  fullWidth
                  onClick={logGoogleUser}
                  disabled={loading}
                  sx={{
                    color: "#1E293D",
                    fontSize: "16px",
                    height: "52px",
                    textTransform: "none",
                    borderWidth: "2px",
                    borderColor: "#E2E8F0",
                    borderRadius: "8px"
                  }}
                  startIcon={<img src={googleIcon} height="22px" style={{ paddingRight: "4px" }} alt="Google Icon" />}
                >
                  Sign In with Google
                </Button>
              </Box>
            </Box>
          ) : (
            <Box sx={{ textAlign: "center", maxWidth: "500px", p: 3 }}>
              <Typography variant="h4" sx={{ mb: 2 }}>
                Access Link Sent to Your Email
              </Typography>
              <Typography sx={{ color: "#ABABAB" }}>
                A secure access link has been sent to your email address. Please check your inbox and click the link to log in.
              </Typography>
            </Box>
          )}
        </Paper>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={snackbarPosition}
        >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {notice}
          </Alert>
        </Snackbar>
      </Grid>
    </Grid>
  );
};

export default HealthcareLogin;