import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch } from '../../store';
import {
    Box,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    TextField,
    IconButton,
    Paper,
    Button,
    Grid,
    Divider,
    styled
} from '@mui/material';
import {
    Queue as QueueIcon,
    Person as PersonIcon,
    Search as SearchIcon,
    Logout as LogoutIcon,
    History as HistoryIcon
} from '@mui/icons-material';
import NavigationDrawer from './NavigationDrawer';
import ConsultationListItem from './components/consultation/ConsultationListItem';
import ConsultationList from './components/consultation/ConsultationList';
import ConsultationDetail from './components/consultation/ConsultationDetail';
import { getMyConsultations, selectMyConsultations } from '../../store/slices/doctorSlice';
import { ConsultationResponse } from '../../types';



const drawerWidth = 240;

const Main = styled('main')(({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    marginRight: '330px',
    height: '100vh',
    overflow: 'auto'
}));



const DoctorConsultation: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();
    const consultations = useSelector(selectMyConsultations);
    const [selectedConsultation, setSelectedConsultation] = useState<ConsultationResponse | null>(null);


    useEffect(() => {
        // Replace 1 with actual doctor ID from your auth state
        dispatch(getMyConsultations(1));
    }, [dispatch]);

    return (
        <Box sx={{ display: 'flex' }}>
            <NavigationDrawer />
            <Box sx={{
                flexGrow: 1,
                p: 3,
                display: 'grid',
                gridTemplateColumns: '1fr 2fr',
                gap: 3,
                height: '100vh',
                overflow: 'hidden'
            }}>
                {/* Consultation List */}
                <Box sx={{ overflow: 'auto' }}>
                    <Typography variant="h6" gutterBottom>
                        All  Consultations
                    </Typography>
                    {consultations.map((consultation) => (
                        <ConsultationListItem
                            key={consultation.visit.id}
                            consultation={consultation}
                            selected={selectedConsultation?.visit.id === consultation.visit.id}
                            onClick={() => setSelectedConsultation(consultation)}
                        />
                    ))}
                </Box>

                {/* Detail View */}
                <Box sx={{ height: '100%', overflow: 'hidden' }}>
                    <ConsultationDetail consultation={selectedConsultation} />
                </Box>
            </Box>



        </Box>
    );
};

export default DoctorConsultation;