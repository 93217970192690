import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';

import logger from 'redux-logger';
import storage from 'redux-persist/lib/storage';
import counterReducer from './slices/counterSlice';
import postsReducer from './slices/postsSlice';
import authReducer from './slices/authSlice';
import clinicReducer from './slices/clinicSlice';
import doctorReducer from './slices/doctorSlice';
import patientReducer from './slices/patientSlice';
import businessReducer from './slices/businessSlice';
import displayReducer from './slices/displaySlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['counter', 'posts', 'auth'],
};

const rootReducer = combineReducers({
  counter: counterReducer,
  posts: postsReducer,
  auth: authReducer,
  clinic: clinicReducer,
  doctor: doctorReducer,
  patient: patientReducer,
  business: businessReducer,
  display: displayReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE', 'persist/REGISTER',],
      },
    }).concat(logger),
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;