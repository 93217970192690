import React from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box,
  Button,
  Container,
  Typography,
  Paper,
  Theme,
  useTheme
} from '@mui/material';
import { SxProps } from '@mui/system';
import PersonIcon from '@mui/icons-material/Person';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';

type StyleDefinition = Record<string, SxProps<Theme>>;

const useStyles = (theme: Theme): StyleDefinition => ({
  root: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    background: 'linear-gradient(45deg, #f3f4f6 30%, #e5e7eb 90%)'
  },
  paper: {
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 3,
    borderRadius: 2,
    boxShadow: theme.shadows[3]
  },
  title: {
    fontWeight: 'bold',
    color: 'primary.main',
    mb: 2,
    textAlign: 'center'
  },
  subtitle: {
    mb: 4,
    color: 'text.secondary',
    textAlign: 'center'
  },
  patientButton: {
    py: 2,
    textTransform: 'none',
    fontSize: '1.1rem',
    fontWeight: 500,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    mb: 2
  },
  healthcareButton: {
    py: 2,
    textTransform: 'none',
    fontSize: '1.1rem',
    fontWeight: 500,
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
    }
  }
});

interface IndexPageProps {
  customTitle?: string;
  onLoginSuccess?: () => void;
}

const IndexPage: React.FC<IndexPageProps> = ({
  customTitle = 'Welcome',
  onLoginSuccess
}) => {
  const theme = useTheme();
  const styles = useStyles(theme);
  const navigate = useNavigate();

  const handlePatientLogin = (): void => {
    navigate('/patient-login');
    onLoginSuccess?.();
  };

  const handleHealthcareLogin = (): void => {
    navigate('/healthcare-login');
    onLoginSuccess?.();
  };

  return (
    <Box sx={styles.root}>
      <Container maxWidth="sm">
        <Paper elevation={3} sx={styles.paper}>
          <Typography 
            variant="h3" 
            component="h1" 
            sx={styles.title}
          >
            {customTitle}
          </Typography>
          
          <Typography 
            variant="h6" 
            sx={styles.subtitle}
          >
            Please select your login type
          </Typography>

          <Button
            variant="contained"
            size="large"
            startIcon={<PersonIcon />}
            onClick={handlePatientLogin}
            fullWidth
            sx={styles.patientButton}
          >
            Patient Login
          </Button>

          <Button
            variant="contained"
            size="large"
            startIcon={<LocalHospitalIcon />}
            onClick={handleHealthcareLogin}
            fullWidth
            sx={styles.healthcareButton}
          >
            Healthcare Login
          </Button>
        </Paper>
      </Container>
    </Box>
  );
};

export default IndexPage;