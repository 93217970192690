import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { authService } from '../../api/services/authService';

interface User {
  displayName?: string;
  stsTokenManager?: any;
  userType?: string;
  role?: string;
  // Add other user properties as needed
}

interface AuthState {
  loggedIn: boolean;
  tryAllLogin: boolean;
  submitsucc: boolean;
  email: string;
  otptype: string;
  userData: User[];
  companyData: Record<string, any>;
  projectData: Record<string, any>;
  finaceData: Record<string, any>;
  profileCompleted: boolean;
  user?: User;
  token?: any;
  username?: string;
  type?: string;
  completeOnboarding?: boolean;
  TnC?: boolean;
  GetStarted?: boolean;
  userType?: string;
  role?: string;
}

const initialState: AuthState = {
  loggedIn: false,
  tryAllLogin: false,
  submitsucc: false,
  email: "",
  otptype: "",
  userData: [],
  companyData: {},
  projectData: {},
  finaceData: {},
  profileCompleted: false,
  role: "",
};

// Async thunks
export const logout = createAsyncThunk(
  'auth/logout',
  async (raw: any) => {
    const response = await authService.postLogout(raw);
    return response;
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      const userData = action.payload;
      const completeOnboarding = action.payload.completeOnboarding;
      
      // Store in localStorage
      localStorage.setItem("loggedIn", "true");
      localStorage.setItem("user", JSON.stringify(userData?.user));
      localStorage.setItem("tokenResponse", JSON.stringify(userData?.user?.stsTokenManager));

      // Update state
      state.user = userData?.user;
      state.token = userData?._tokenResponse;
      state.loggedIn = true;
      state.username = userData?.user?.displayName;
      state.type = userData?.providerId;
      state.completeOnboarding = completeOnboarding;
      state.userType = userData.userType;
      state.role = userData.role;
    },
    logoutSuccess:(state) =>{
        state.loggedIn = false;
        // Clear other auth-related state as needed
        state.token = {};
        state.userType = "";
        state.role = "";
        localStorage.removeItem("loggedIn");
        localStorage.removeItem("user");
        localStorage.removeItem("tokenResponse");
    },
    setCompanyData: (state, action) => {
      state.companyData = action.payload;
    },
    setProjectData: (state, action) => {
      state.projectData = action.payload;
    },
    setFinanceData: (state, action) => {
      state.finaceData = action.payload;
      state.completeOnboarding = true;
    },
    updateFinanceData: (state, action) => {
      state.finaceData = action.payload;
    },
    setTermsAndConditions: (state, action) => {
      state.TnC = action.payload;
    },
    setGetStarted: (state, action) => {
      state.GetStarted = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(logout.pending, (state) => {
        // Handle pending state if needed
      })
      .addCase(logout.fulfilled, (state) => {
        state.loggedIn = false;
        // Clear other auth-related state as needed
        localStorage.removeItem("loggedIn");
        localStorage.removeItem("user");
        localStorage.removeItem("tokenResponse");
      })
      .addCase(logout.rejected, (state) => {
        // Handle error state if needed
      });
  },
});

// Export actions
export const {
  loginSuccess,
  logoutSuccess,
  setCompanyData,
  setProjectData,
  setFinanceData,
  updateFinanceData,
  setTermsAndConditions,
  setGetStarted,
} = authSlice.actions;

// Export selectors
export const selectAuth = (state: { auth: AuthState }) => state.auth;
export const selectIsLoggedIn = (state: { auth: AuthState }) => state.auth.loggedIn;
export const selectUser = (state: { auth: AuthState }) => state.auth.user;
export const selectUserRole = (state: { auth: AuthState }) => state.auth.role;
export const selectCompanyData = (state: { auth: AuthState }) => state.auth.companyData;

export default authSlice.reducer;