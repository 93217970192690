import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  TextField,
  IconButton,
  Paper,
  Button,
  Grid,
  Divider,
  styled
} from '@mui/material';
import {
  Queue as QueueIcon,
  Person as PersonIcon,
  Search as SearchIcon,
  Logout as LogoutIcon,
  History as HistoryIcon
} from '@mui/icons-material';
import NavigationDrawer from './NavigationDrawer';
import PatientRegistration from './patientRegistration';
 

interface PatientVisit {
  date: string;
  diagnosis: string;
  prescription: string;
  notes: string;
}

interface Patient {
  id: string;
  name: string;
  // Add other patient properties as needed
}

const drawerWidth = 240;

const Main = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  
  height: '100vh',
  overflow: 'auto'
}));

const HistorySidebar = styled(Paper)(({ theme }) => ({
  position: 'fixed',
  right: 0,
  top: 0,
  width: '300px',
  height: '100vh',
  padding: theme.spacing(2),
  borderLeft: `1px solid ${theme.palette.divider}`,
  overflow: 'auto'
}));

const PatientDashboard: React.FC = () => {
  const navigate = useNavigate();
  const [selectedPatient, setSelectedPatient] = useState<Patient | null>(null);
  const [notes, setNotes] = useState<string>('');
  const [patientHistory, setPatientHistory] = useState<PatientVisit[]>([]);
  const dispatch = useDispatch();

  const mockHistory: PatientVisit[] = [
    {
      date: '2024-01-15',
      diagnosis: 'Common Cold',
      prescription: 'Antihistamines, Cough Syrup',
      notes: 'Patient reported fever and cough'
    },
    {
      date: '2023-11-20',
      diagnosis: 'Hypertension Follow-up',
      prescription: 'Lisinopril 10mg',
      notes: 'Blood pressure under control'
    }
  ];

  useEffect(() => {
    setPatientHistory(mockHistory);
  }, []);

  const handleLogout = async (e: React.MouseEvent<HTMLElement>): Promise<void> => {
    try {
      e.preventDefault();
      // await dispatch(authActions.logoutSuccess());
      navigate("/");
    } catch (error) {
      console.error("Error signing out: ", error);
      navigate('/');
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <NavigationDrawer />

      <Main>
        
      <PatientRegistration />
   
      </Main>

 
    </Box>
  );
};

export default PatientDashboard;