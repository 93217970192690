// src/services/businessService.ts

import { AxiosResponse } from 'axios';
import axiosInstance from '../axios';
import { API_ENDPOINTS } from '../endpoints';
import { DoctorInfo, ClinicRoom } from '../types';

export const businessService = {
  setClinicActive: (clinicId: number, toEnable: boolean): Promise<AxiosResponse<any>> => {
    return axiosInstance.post(API_ENDPOINTS.BUSINESS.SET_CLINIC_ACTIVE, null, {
      params: { clinicId, toEnable },
    });
  },

  addSpecialityDoctor: (doctorInfo: DoctorInfo): Promise<AxiosResponse<any>> => {
    return axiosInstance.post(API_ENDPOINTS.BUSINESS.ADD_SPECIALITY_DOCTOR, doctorInfo);
  },

  addRoom: (room: ClinicRoom): Promise<AxiosResponse<any>> => {
    return axiosInstance.post(API_ENDPOINTS.BUSINESS.ADD_ROOM, room);
  },

  getAllClinics: (): Promise<AxiosResponse<any>> => {
    return axiosInstance.get(API_ENDPOINTS.BUSINESS.GET_ALL_CLINICS);
  },
};