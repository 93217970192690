import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { clinicService } from '../../api/services/clinicService';
import { ClinicInfo, DoctorInfo, ClinicRoom, UReceptionInfo } from '../../api/types';

interface ClinicState {
  clinics: ClinicInfo[];
  currentClinic: ClinicInfo | null;
  doctors: DoctorInfo[];
  rooms: ClinicRoom[];
  loading: boolean;
  error: string | null;
}

const initialState: ClinicState = {
  clinics: [],
  currentClinic: null,
  doctors: [],
  rooms: [],
  loading: false,
  error: null,
};

// Async thunks
export const addClinic = createAsyncThunk(
  'clinic/addClinic',
  async (clinicInfo: ClinicInfo) => {
    const response = await clinicService.addClinic(clinicInfo);
    return response.data;
  }
);

export const getClinic = createAsyncThunk(
  'clinic/getClinic',
  async (clinicId: number) => {
    const response = await clinicService.getClinic(clinicId);
    return response.data;
  }
);

export const addDoctor = createAsyncThunk(
  'clinic/addDoctor',
  async ({ clinicId, doctorInfo }: { clinicId: number; doctorInfo: DoctorInfo }) => {
    const response = await clinicService.createAddDoctor(clinicId, doctorInfo);
    return response.data;
  }
);

export const addRoom = createAsyncThunk(
  'clinic/addRoom',
  async (room: ClinicRoom) => {
    const response = await clinicService.addRoom(room);
    return response.data;
  }
);

export const clinicSlice = createSlice({
  name: 'clinic',
  initialState,
  reducers: {
    setCurrentClinic: (state, action) => {
      state.currentClinic = action.payload;
    },
    clearClinicData: (state) => {
      state.currentClinic = null;
      state.doctors = [];
      state.rooms = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // Add Clinic
      .addCase(addClinic.pending, (state) => {
        state.loading = true;
      })
      .addCase(addClinic.fulfilled, (state, action) => {
        state.loading = false;
        state.clinics.push(action.payload);
        state.currentClinic = action.payload;
      })
      .addCase(addClinic.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to add clinic';
      })
      // Get Clinic
      .addCase(getClinic.pending, (state) => {
        state.loading = true;
      })
      .addCase(getClinic.fulfilled, (state, action) => {
        state.loading = false;
        state.currentClinic = action.payload;
      })
      .addCase(getClinic.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch clinic';
      })
      // Add Doctor
      .addCase(addDoctor.fulfilled, (state, action) => {
        state.doctors.push(action.payload);
      })
      // Add Room
      .addCase(addRoom.fulfilled, (state, action) => {
        state.rooms.push(action.payload);
      });
  },
});

export const { setCurrentClinic, clearClinicData } = clinicSlice.actions;

// Selectors
export const selectClinic = (state: { clinic: ClinicState }) => state.clinic;
export const selectCurrentClinic = (state: { clinic: ClinicState }) => state.clinic.currentClinic;
export const selectDoctors = (state: { clinic: ClinicState }) => state.clinic.doctors;
export const selectRooms = (state: { clinic: ClinicState }) => state.clinic.rooms;
export const selectClinicLoading = (state: { clinic: ClinicState }) => state.clinic.loading;
export const selectClinicError = (state: { clinic: ClinicState }) => state.clinic.error;

export default clinicSlice.reducer;