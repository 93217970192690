import React from 'react';
import {
    Card,
    CardContent,
    Box,
    Typography,
    Chip,
    styled
} from '@mui/material';
import { AccessTime as AccessTimeIcon } from '@mui/icons-material';
import { ConsultationResponse } from '../../../../types';

interface ConsultationListItemProps {
    consultation: ConsultationResponse;
    selected: boolean;
    onClick: () => void;
}

const StatusChip = styled(Chip)(({ theme }) => ({
    '&.waiting': {
        backgroundColor: theme.palette.warning.light,
        color: theme.palette.warning.contrastText,
    },
    '&.completed': {
        backgroundColor: theme.palette.success.light,
        color: theme.palette.success.contrastText,
    }
}));

const ConsultationListItem: React.FC<ConsultationListItemProps> = ({ 
    consultation, 
    selected, 
    onClick 
}) => {
    const { patient, visit } = consultation;

    return (
        <Card 
            onClick={onClick}
            sx={{ 
                mb: 2, 
                cursor: 'pointer',
                bgcolor: selected ? 'action.selected' : 'background.paper',
                '&:hover': {
                    bgcolor: 'action.hover',
                }
            }}
        >
            <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6">
                        {patient.title} {patient.fname} {patient.lname}
                    </Typography>
                    <StatusChip 
                        label={visit.status}
                        className={visit.status.toLowerCase()}
                    />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                    <AccessTimeIcon sx={{ mr: 1, fontSize: 'small' }} />
                    <Typography variant="body2">
                        Token: {visit.token} | {new Date(visit.visitDate).toLocaleTimeString()}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
};

export default ConsultationListItem;