import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  TextField,
  IconButton,
  Paper,
  Button,
  Grid,
  Divider,
  styled
} from '@mui/material';
import {
  Queue as QueueIcon,
  Person as PersonIcon,
  Search as SearchIcon,
  Logout as LogoutIcon,
  History as HistoryIcon
} from '@mui/icons-material';
import NavigationDrawer from './NavigationDrawer';
 
 

const drawerWidth = 240;

const Main = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  marginRight: '330px',
  height: '100vh',
  overflow: 'auto'
}));

 

const DoctorSearch: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
   
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <NavigationDrawer />

      <Main>
        <h2>DoctorSearch</h2>
      </Main>

 
    </Box>
  );
};

export default DoctorSearch;