// src/services/clinicService.ts

import { AxiosResponse } from 'axios';
import axiosInstance from '../axios';
import { API_ENDPOINTS } from '../endpoints';
import { ClinicInfo, DoctorInfo, ClinicRoom, UReceptionInfo } from '../types';

export const clinicService = {
  addClinic: (clinicInfo: ClinicInfo): Promise<AxiosResponse<any>> => {
    return axiosInstance.post(API_ENDPOINTS.CLINIC.ADD_CLINIC, clinicInfo);
  },

  linkClinicDoctor: (clinicId: number, docId: number): Promise<AxiosResponse<any>> => {
    return axiosInstance.post(API_ENDPOINTS.CLINIC.LINK_DOCTOR, null, {
      params: { clinicId, docId },
    });
  },

  createAddDoctor: (clinicId: number, doctorInfo: DoctorInfo): Promise<AxiosResponse<any>> => {
    return axiosInstance.post(API_ENDPOINTS.CLINIC.CREATE_ADD_DOCTOR, null, {
      params: { clinicId, doc: doctorInfo },
    });
  },

  addSpecialityDoctor: (doctorInfo: DoctorInfo): Promise<AxiosResponse<any>> => {
    return axiosInstance.post(API_ENDPOINTS.CLINIC.ADD_SPECIALITY_DOCTOR, doctorInfo);
  },

  addRoom: (room: ClinicRoom): Promise<AxiosResponse<any>> => {
    return axiosInstance.post(API_ENDPOINTS.CLINIC.ADD_ROOM, room);
  },

  addReception: (receptionInfo: UReceptionInfo): Promise<AxiosResponse<any>> => {
    return axiosInstance.post(API_ENDPOINTS.CLINIC.ADD_RECEPTION, receptionInfo);
  },

  getClinic: (clinicId: number): Promise<AxiosResponse<any>> => {
    return axiosInstance.get(API_ENDPOINTS.CLINIC.GET_CLINIC, {
      params: { clinicId },
    });
  },

  findDoctor: (key: string): Promise<AxiosResponse<any>> => {
    return axiosInstance.get(API_ENDPOINTS.CLINIC.FIND_DOCTOR, {
      params: { key },
    });
  },
};