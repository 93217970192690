import React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { Patient } from '../../../../types';

interface PatientDetailsProps {
    patient: Patient;
}

const PatientDetails: React.FC<PatientDetailsProps> = ({ patient }) => {
    return (
        <Box sx={{ mb: 3 }}>
            <Typography variant="h6">
                {patient.title} {patient.fname} {patient.lname}
            </Typography>
            <Typography variant="body1">
                Gender: {patient.gender}
            </Typography>
            <Typography variant="body1">
                DOB: {new Date(patient.dateOfBirth).toLocaleDateString()}
            </Typography>
            <Typography variant="body1">
                Phone: {patient.phone}
            </Typography>
            <Typography variant="body1">
                Email: {patient.email}
            </Typography>
            <Typography variant="body1">
                Address: {patient.address}
            </Typography>
        </Box>
    );
};

export default PatientDetails;